html, body, * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: #fff;
  background-color: #e50073;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  font-family: Poppins, sans-serif;
  display: flex;
}

h1 {
  text-align: center;
  font-size: 3rem;
}

h2 {
  text-align: center;
  font-size: 2rem;
}

p {
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: lighter;
}

a {
  color: #fff;
  text-decoration: none;
  transition: color .3s;
}

a:hover {
  color: #000;
}

ul, ol {
  margin-left: 2rem;
}

ul li, ol li {
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: lighter;
}

label {
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: normal;
  display: block;
}

input, textarea, select {
  color: #000;
  background-color: #0000;
  border: 1px solid #dfdfdf;
  outline: none;
  width: 100%;
  margin-bottom: 1rem;
  padding: .5rem;
}

input[type="submit"], textarea[type="submit"], select[type="submit"] {
  color: #fff;
  cursor: pointer;
  background-color: #e50073;
  border: none;
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: bold;
  transition: background-color .3s;
}

input[type="submit"]:hover, textarea[type="submit"]:hover, select[type="submit"]:hover {
  background-color: #000;
}

.form {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 1rem;
}

img {
  max-width: 100%;
}

header {
  opacity: .9;
  z-index: 1;
  background-color: #e50073;
  width: 100%;
  padding: .8rem 1rem;
  position: fixed;
}

header .content {
  flex-direction: row;
  align-items: center;
}

header .logo {
  width: 200px;
  max-width: 80px;
}

header nav ul {
  gap: 1rem;
  list-style: none;
  display: flex;
}

header nav ul li a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.content {
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
}

main {
  width: 100%;
  position: relative;
}

.anchor {
  visibility: hidden;
  display: block;
  position: relative;
  top: -150px;
}

.full-height {
  min-height: calc(100vh - 200px);
}

.only {
  justify-content: center;
  align-items: center;
  display: flex;
}

section {
  color: #e50073;
  background-color: #fff;
  border-top: 2px solid #dfdfdf;
  width: 100%;
  padding: 4rem 1rem;
}

section a {
  color: #e50073;
  text-decoration: none;
  transition: color .3s;
}

section a:hover {
  color: #000;
}

footer {
  width: 100%;
  padding: 2rem 1rem;
}

footer .content {
  flex-direction: row;
  gap: 1rem;
  display: flex;
}

footer p {
  margin: 0;
  font-size: 1rem;
}

@media only screen and (width <= 600px) {
  main {
    font-size: 10vw;
  }
}

#inicio {
  color: #e50073;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  display: flex;
  position: relative;
}

#inicio .portada {
  max-width: 500px;
  margin: 0 auto 2rem;
}

#inicio .tags {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
  padding-left: 0;
  list-style: none;
  display: flex;
}

#inicio .tags li {
  --size: 4;
  font-size: calc(var(--size) * .25rem + .5rem);
  line-height: calc(var(--size) * .25rem + .5rem);
  padding: .125rem 1.25rem;
  text-decoration: none;
  display: block;
  position: relative;
}

#inicio .tags li[data-weight="1"] {
  --size: 1;
}

#inicio .tags li[data-weight="2"] {
  --size: 2;
}

#inicio .tags li[data-weight="3"] {
  --size: 3;
}

#inicio .tags li[data-weight="4"] {
  --size: 4;
}

#inicio .tags li[data-weight="5"] {
  --size: 6;
}

#inicio .tags li[data-weight="6"] {
  --size: 8;
}

#inicio .tags li[data-weight="7"] {
  --size: 10;
}

#inicio .tags li[data-weight="8"] {
  --size: 13;
}

#inicio .tags li[data-weight="9"] {
  --size: 16;
}

#inicio .tags[data-show-value] li:after {
  content: " (" attr(data-weight) ")";
  font-size: 1.5rem;
}

#inicio .tags li:focus {
  outline: 1px dashed;
}

#inicio .tags li:before {
  content: "";
  opacity: .15;
  width: 0;
  height: 100%;
  transition: width .25s;
  position: absolute;
  top: 0;
  left: 50%;
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

#inicio .tags li:focus:before, #inicio .tags li:hover:before {
  width: 100%;
}

@media (prefers-reduced-motion) {
  #inicio .tags ul {
    transition: none !important;
  }
}

@media (width <= 768px) {
  #inicio .tags li[data-weight="1"] {
    --size: .5;
  }

  #inicio .tags li[data-weight="2"] {
    --size: 1;
  }

  #inicio .tags li[data-weight="3"] {
    --size: 1.5;
  }

  #inicio .tags li[data-weight="4"] {
    --size: 2;
  }

  #inicio .tags li[data-weight="5"] {
    --size: 2.5;
  }

  #inicio .tags li[data-weight="6"] {
    --size: 3;
  }

  #inicio .tags li[data-weight="7"] {
    --size: 3.5;
  }

  #inicio .tags li[data-weight="8"] {
    --size: 4;
  }

  #inicio .tags li[data-weight="9"] {
    --size: 4.5;
  }
}

.first {
  padding-top: 100px;
}

.donde-estamos .info {
  grid-template-columns: 2fr 1fr;
  display: grid;
}

.donde-estamos .direccion {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.donde-estamos .direccion p {
  margin-bottom: 1rem;
}

.donde-estamos .direccion strong {
  min-width: 120px;
  display: inline-block;
}

@media (width <= 768px) {
  .donde-estamos .info {
    grid-template-columns: 1fr;
  }

  .donde-estamos .mapa iframe {
    width: 100%;
  }
}

.sobre-nosotros .fotos {
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  display: grid;
}

@media screen and (width <= 600px) {
  .sobre-nosotros .fotos {
    grid-template-columns: 1fr;
  }
}

.fondo .logos {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.fondo .logos img {
  width: 100%;
  max-width: 200px;
}

.fondo .implantacion {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
}

.fondo .implantacion img {
  width: 100%;
  max-width: 600px;
}

@media (width <= 768px) {
  .fondo .logos {
    flex-direction: column;
  }
}

.politicas p, .politicas li {
  font-size: 1rem;
}

table thead {
  vertical-align: middle;
  border-color: inherit;
  display: table-header-group;
}

table tbody {
  vertical-align: middle;
  border-color: inherit;
  display: table-row-group;
}

table td {
  padding: 0 .5rem;
}

table th {
  text-align: center;
  background: #ccc;
  padding: 0 .5rem;
}

#cookies {
  color: #000;
  background: #b6b2b5cc;
  border-radius: 5px;
  max-width: 340px;
  padding: 1rem;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

#cookies .wrapper {
  margin: 0;
  padding: 0;
}

#cookies p {
  font-size: .8rem;
}

#cookies .botones {
  text-align: right;
}

#cookies .botones a, #cookies .botones button {
  font-size: 1rem;
}

#cookies .botones button {
  color: #fff;
  background-color: #2e9248;
  border: 0;
  border-radius: 3px;
  margin-left: 1rem;
  padding: .5rem 1rem;
  font-size: 1rem;
}

.cita {
  gap: 1rem;
  display: flex;
}

.glass {
  -webkit-backdrop-filter: blur(5px);
  background: #fff3;
  border: 1px solid #ffffff4d;
  border-radius: 16px;
  box-shadow: 0 4px 30px #0000001a;
}

.bounce {
  animation: 5s infinite bounce;
  position: absolute;
  bottom: 2rem;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -o-transform: translateY(0);
  }

  40% {
    -o-transform: translateY(-30px);
  }

  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.arrowDown {
  border: 1px solid #e50073;
  border-width: 0 1px 1px 0;
  padding: 1rem;
  display: inline-block;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 15px 14px 24px -20px #fff;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.subrayado {
  text-decoration: underline;
}

.whatsapp {
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
}
/*# sourceMappingURL=index.8ee4d139.css.map */
